import { SupportedLanguages } from '@as/api';

export function getOptionsForDynamicSelectModel<T>(
  e: T,
  translationName: string,
  optional: boolean,
  toLower = true
): {
  label: string;
  value: T;
}[] {
  let options = [];
  if (optional) {
    options = [{ value: '', label: 'APPLICATION.FORM.select' }];
  }

  return options.concat(
    Object.keys(e).map(name => {
      const label = toLower ? `${name.charAt(0).toLocaleLowerCase() + name.slice(1)}` : name;
      return {
        value: e[name] as T,
        label: `APPLICATION.DICTIONARIES.${translationName}.${label}`
      };
    })
  );
}

export function getOptionsForDynamicSelectModelStrip<T>(
  e: T,
  translationName: string,
  optional: boolean,
  toLower = true
): {
  label: string;
  value: T;
}[] {
  let options = [];
  if (optional) {
    options = [{ value: '', label: 'APPLICATION.FORM.select' }];
  }

  return options.concat(
    Object.keys(e).map(name => {
      const label = toLower ? `${name.charAt(0).toLocaleLowerCase() + name.slice(1)}` : name;
      return {
        value: name as T,
        label: `APPLICATION.DICTIONARIES.${translationName}.${label}`
      };
    })
  );
}

export function getBooleanOptionsForDynamicSelectModel(optional: boolean): {
  label: string;
  value: string | boolean;
}[] {
  const options: { label: string; value: string | boolean }[] = [];
  if (optional) {
    options.push({ value: '', label: 'APPLICATION.FORM.select' });
  }
  options.push({
    value: true,
    label: 'APPLICATION.DICTIONARIES.TRUE_FALSE.true'
  });
  options.push({
    value: false,
    label: 'APPLICATION.DICTIONARIES.TRUE_FALSE.false'
  });
  return options;
}

export function getLanguageOptionsForDynamicSelectModel(): {
  label: string;
  value: string | boolean;
}[] {
  return Object.keys(SupportedLanguages).map(name => {
    let value = SupportedLanguages[name];
    value = `${value.charAt(0).toUpperCase() + value.slice(1)}`;
    return {
      value: value,
      label: `APPLICATION.LANGUAGES.${name.toLocaleLowerCase()}`
    };
  });
}

export function getOptionsForDynamicMultiSelectControlModel<T>(e: T): { label: string; value: T }[] {
  return Object.keys(e).map(name => {
    return {
      value: e[name] as T,
      label: `${name.charAt(0).toLocaleLowerCase() + name.slice(1)}`
    };
  });
}
