import { Settings } from 'luxon';
import { StorageService } from '../storage/storage.service';
import { SupportedLanguages } from './supported-languages.enum';
import { Injectable, Inject, InjectionToken } from '@angular/core';
import { TranslateService as NgxTranslateService } from '@ngx-translate/core';
import { TranslateModuleOptions } from './translate-module-options';
import { registerLocaleData } from '@angular/common';

import localePl from '@angular/common/locales/pl';
import localeEn from '@angular/common/locales/en';
import localeIt from '@angular/common/locales/it';

const KEY_LANG = 'lang';

// NOTE: This value has to be exported otherwise the AoT compiler won't see it.
export const TRANSLATE_MODULE_OPTIONS_CONFIG = new InjectionToken<TranslateModuleOptions>('Translate Module Optionsx');

@Injectable({
  providedIn: 'root'
})
export class TranslateService {
  private isInitialized = false;

  constructor(
    @Inject(TRANSLATE_MODULE_OPTIONS_CONFIG)
    private translateModuleOptions: TranslateModuleOptions,
    private translateService: NgxTranslateService,
    private storageService: StorageService
  ) {}

  setLanguage(lang: SupportedLanguages) {
    this.initializeLuxon(lang);
    this.translateService.use(lang);
    this.storageService.setItem(KEY_LANG, lang);
  }

  getLanguage(): SupportedLanguages {
    return this.storageService.getItem(KEY_LANG) || this.translateModuleOptions.defaultLanguage;
  }

  supportedLanguages(): string[] {
    return Object.entries(SupportedLanguages).map(([, value]) => value);
  }

  initialize() {
    if (!this.isInitialized) {
      const language = this.getLanguage();
      this.translateService.addLangs(Object.keys(SupportedLanguages));
      this.translateService.setDefaultLang(language);
      this.translateService.use(language);
      this.initializeLuxon(language);
      this.registerLocale();

      this.isInitialized = true;
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  translate(key: string | Array<string>, interpolateParams?: any): string {
    return this.translateService.instant(key, interpolateParams);
  }

  private registerLocale() {
    registerLocaleData(localePl, SupportedLanguages.PL);
    registerLocaleData(localeEn, SupportedLanguages.EN);
    registerLocaleData(localeIt, SupportedLanguages.IT);
  }

  private initializeLuxon(lang: SupportedLanguages) {
    Settings.defaultLocale = lang;
  }
}
