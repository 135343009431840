import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ErrorsHandler, LoggerService } from 'farmcloud-core';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ClarityRoutingService implements OnDestroy {
  private routerSub: Subscription;

  constructor(
    private router: Router,
    private errorsHandler: ErrorsHandler,
    private loggerService: LoggerService,
    @Inject(DOCUMENT) document: Document
  ) {
    this.routerSub = this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
      if (!this.loggerService.isClarityInitialized) {
        return;
      }

      try {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        clarity('set', 'pageTitle', document.title);
      } catch (error) {
        this.errorsHandler.handleError(new Error('Cannot set custom tag for this page - Clarity is not installed' + error));
      }
    });
  }

  ngOnDestroy() {
    if (this.routerSub) this.routerSub.unsubscribe();
  }
}
