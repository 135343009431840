import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppConfigurationService } from 'farmcloud-core';
import { ResetPasswordDto } from '../models/reset-password.dto';
import { TokenAuthResultDto } from '../models/token-auth-result.dto';
import { UserCredentials } from '../models/user-credentials.dto';
import { UserRegisterDto } from '../models/user-register.dto';
import { RemindPasswordDto } from '../models/remind-password.dto';
import { ConfirmEmailInputDto } from '../models/confirm-email-input.model';
import { SendEmailActivationLinkInputDto } from '../models/send-email-activation-link-input.model';
import { AcceptInvitationDto } from '../models/accept-invitation.dto';
import { InvitationFormDto } from '../models/invitation-form.dto';

@Injectable({
  providedIn: 'root'
})
export class AuthProxyService {
  private readonly baseTokenAuthUrl: string;
  private readonly baseUserAuthUrl: string;
  private readonly baseInvitationUrl: string;

  constructor(
    private http: HttpClient,
    config: AppConfigurationService
  ) {
    this.baseTokenAuthUrl = `${config.configuration.api_url}/api/TokenAuth`;
    this.baseUserAuthUrl = `${config.configuration.api_url}/api/UserAuth`;
    this.baseInvitationUrl = `${config.configuration.api_url}/api/Invitation`;
  }

  public token(tokenRequestDto: UserCredentials): Observable<TokenAuthResultDto> {
    const url = `${this.baseTokenAuthUrl}/Authenticate`;
    return this.http.post<TokenAuthResultDto>(url, tokenRequestDto);
  }

  public register(input: UserRegisterDto): Observable<unknown> {
    const url = `${this.baseUserAuthUrl}/Register`;
    return this.http.post(url, input || {});
  }

  public remindPassword(data: RemindPasswordDto): Observable<unknown> {
    const url = `${this.baseUserAuthUrl}/RemindPassword`;
    return this.http.post(url, data || {});
  }

  public resetPassword(data: ResetPasswordDto) {
    const url = `${this.baseUserAuthUrl}/ResetPassword`;
    return this.http.post(
      url,
      {
        ...data
      } || {}
    );
  }

  public confirmEmail(input: ConfirmEmailInputDto) {
    const url = `${this.baseUserAuthUrl}/ConfirmEmail`;
    return this.http.post(url, input || {});
  }

  public sendEmailActivationLink$(input: SendEmailActivationLinkInputDto) {
    const url = `${this.baseUserAuthUrl}/SendEmailActivationLink`;
    return this.http.post(url, input || {});
  }

  public acceptInvitation(data: AcceptInvitationDto): Observable<void> {
    const url = `${this.baseInvitationUrl}/AcceptInvitation`;
    return this.http.post<void>(url, data);
  }

  public getInvitation(invitationId: string): Observable<InvitationFormDto> {
    const url = `${this.baseInvitationUrl}/${invitationId}`;
    return this.http.get<InvitationFormDto>(url);
  }
}
