import { Component, OnInit } from '@angular/core';

import { InputBaseComponent } from '../input.base';
import { UntypedFormControl, Validators } from '@angular/forms';
import { filter, take } from 'rxjs/operators';

@Component({
  selector: 'app-input-phone-number',
  templateUrl: './input-phone-number.component.html',
  styleUrls: ['./../input.base.scss']
})
export class InputPhoneNumberComponent extends InputBaseComponent implements OnInit {
  public phoneNoMask = ['+', '4', '8', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/];
  public phoneNoControl = new UntypedFormControl('');

  ngOnInit() {
    this.setControlValidation();
    this.setPhoneNoValue();
    this.syncControlsValues();
  }

  private setControlValidation(): void {
    const regex = '^\\+48 [0-9][0-9][0-9]-[0-9][0-9][0-9]-[0-9][0-9][0-9]';
    const phoneValidator = Validators.pattern(regex);
    const validator = this.inputControl.validator;
    if (validator === null) {
      this.phoneNoControl.setValidators([phoneValidator]);
      return;
    }
    this.phoneNoControl.setValidators([validator, phoneValidator]);
  }

  private setPhoneNoValue(): void {
    this.inputControl.valueChanges.pipe(take(1)).subscribe(value => {
      this.phoneNoControl.setValue(value, { emitEvent: false });
    });
  }

  private syncControlsValues(): void {
    this.phoneNoControl.valueChanges.pipe(filter(() => this.phoneNoControl.valid)).subscribe(value => {
      const unformattedValue = this.formatPhoneNo(value);
      this.inputControl.setValue(unformattedValue, { emitEvent: false });
    });
  }

  private formatPhoneNo(phoneNumber: string): string {
    return phoneNumber.replace(/\s+/g, ' ');
  }

  public isInputControlInvalid(): boolean {
    return this.phoneNoControl.errors && (this.phoneNoControl.dirty || this.phoneNoControl.touched);
  }
}
