import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { GoogleMapsLoaderService } from '../../common/services/google-maps-loader.service';

@Component({
  selector: 'app-pure-map',
  templateUrl: './pure-map.component.html',
  styleUrls: ['./pure-map.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PureMapComponent implements AfterViewInit {
  @ViewChild('mapDiv') mapDiv: ElementRef;
  map: google.maps.Map;

  @Input() height = 500;
  @Input() tilt = 45;
  @Output() mapInitialized = new EventEmitter<google.maps.Map>();

  constructor(private googleMapsLoaderService: GoogleMapsLoaderService) {}

  async ngAfterViewInit() {
    this.map = await this.googleMapsLoaderService.initMap(this.mapDiv, this.tilt);
    this.mapInitialized.emit(this.map);
  }
}
