import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Injectable } from '@angular/core';
import { ShortDatePipe } from 'farmcloud-core';

@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {
  constructor(private readonly shortDatePipe: ShortDatePipe) {
    super();
  }

  parse(value: string): NgbDateStruct {
    if (!value) {
      return null;
    }

    const dateParts = value.trim().split('.');
    if (dateParts.length !== 3) {
      return null;
    }

    return { year: +dateParts[2], month: +dateParts[1], day: +dateParts[0] };
  }

  format(date: NgbDateStruct): string {
    if (date === null) {
      return '';
    }

    const dateObject = new Date(date.year, date.month - 1, date.day);
    return this.shortDatePipe.transform(dateObject);
  }
}
