import { ElementRef, Injectable } from '@angular/core';
import { AppConfigurationService } from 'farmcloud-core';
import { Loader } from 'google-maps';

@Injectable({ providedIn: 'root' })
export class GoogleMapsLoaderService {
  private loadingScriptPromise: Promise<void> | null = null;
  private isScriptLoaded = false;

  private readonly DEFAULT_LATITUDE = 52.16045455774706;
  private readonly DEFAULT_LONGITUDE = 19.1162109375;
  private readonly DEFAULT_ZOOM = 7;

  constructor(private appConfigurationService: AppConfigurationService) {}

  async initMap(mapDiv: ElementRef, tilt: number) {
    await this.loadGoogleMaps();
    const mapOptions = this.getDefaultMapOptions(tilt);
    const googleMap = new google.maps.Map(mapDiv.nativeElement, mapOptions);
    return googleMap;
  }

  public async loadGoogleMaps(): Promise<void> {
    if (this.isScriptLoaded) {
      return;
    }

    if (this.loadingScriptPromise) {
      return this.loadingScriptPromise;
    }

    const apiKey = this.appConfigurationService.configuration.google_maps_api_key;

    if (!apiKey) {
      throw new Error('Google Maps API key is missing');
    }

    const loader = new Loader(apiKey, {
      libraries: ['drawing', 'places', 'geometry']
    });

    this.loadingScriptPromise = loader
      .load()
      .then(() => {
        this.isScriptLoaded = true;
      })
      .catch(error => {
        console.error('Error loading Google Maps script', error);
        throw error;
      });

    return this.loadingScriptPromise;
  }

  private getDefaultMapOptions(tilt: number): google.maps.MapOptions {
    return {
      center: { lat: this.DEFAULT_LATITUDE, lng: this.DEFAULT_LONGITUDE },
      zoom: this.DEFAULT_ZOOM,
      mapTypeId: 'hybrid',
      tilt,
      styles: [
        {
          featureType: 'poi',
          elementType: 'labels',
          stylers: [{ visibility: 'off' }]
        }
      ]
    };
  }
}
