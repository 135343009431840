import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ActionButtonsContainerComponent } from '@farm-portal/shared/modules/layout/action-buttons-container/action-buttons-container.component';
import { ActionButtonSecondaryComponent } from '@farm-portal/shared/modules/layout/buttons/action-secondary-button/action-secondary-button.component';
import { EmptyStateComponent } from '@farm-portal/shared/modules/layout/empty-state/empty-state.component';
import { NgbdModalConfirmComponent } from '@farm-portal/shared/modules/layout/ngbd-modal-confirm/ngbd-modal-confirm.component';
import { PageContentComponent } from '@farm-portal/shared/modules/layout/page-content/page-content.component';
import { TabularPageComponent } from '@farm-portal/shared/modules/layout/tabular-page/tabular-page.component';
import { NgbDropdownModule, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { PipesModule } from 'farmcloud-core';
import { ActionButtonComponent } from './buttons/action-button/action-button.component';
import { DangerButtonComponent } from './buttons/danger-button/danger-button.component';
import { IconButtonComponent } from './buttons/icon-button/icon-button.component';
import { PrimaryButtonComponent } from './buttons/primary-button/primary-button.component';
import { FiltersContainerComponent } from './filters-container/filters-container.component';
import { FiltersNoButtonsContainerComponent } from './filters-no-buttons-container/filters-no-buttons-container.component';
import { OverlaySpinnerComponent } from './overlay-spinner/overlay-spinner.component';
import { TitleComponent } from './title/title.component';
import { NgbdModalShareComponent } from '@farm-portal/shared/modules/layout/ngbd-modal-share/ngbd-modal-share.component';
import { FormInputsModule } from '@farm-portal/shared/modules/form-inputs/form-inputs.module';
import { GalleryModalComponent } from './modal-gallery/gallery-modal/gallery-modal.component';

@NgModule({
  declarations: [
    PageContentComponent,
    FiltersContainerComponent,
    PrimaryButtonComponent,
    DangerButtonComponent,
    ActionButtonComponent,
    TitleComponent,
    ActionButtonsContainerComponent,
    ActionButtonSecondaryComponent,
    IconButtonComponent,
    FiltersNoButtonsContainerComponent,
    TabularPageComponent,
    EmptyStateComponent,
    OverlaySpinnerComponent,
    NgbdModalConfirmComponent,
    NgbdModalShareComponent,
    GalleryModalComponent
  ],
  exports: [
    PageContentComponent,
    FiltersContainerComponent,
    ActionButtonComponent,
    DangerButtonComponent,
    PrimaryButtonComponent,
    TitleComponent,
    ActionButtonsContainerComponent,
    ActionButtonSecondaryComponent,
    IconButtonComponent,
    FiltersNoButtonsContainerComponent,
    TabularPageComponent,
    EmptyStateComponent,
    OverlaySpinnerComponent,
    NgbdModalConfirmComponent,
    NgbdModalShareComponent,
    GalleryModalComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    NgbDropdownModule,
    RouterModule,
    NgbNavModule,
    ReactiveFormsModule,
    PipesModule,
    FormInputsModule
  ]
})
export class LayoutModule {}
