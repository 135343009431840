import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appOnlyPositive]'
})
export class OnlyPositiveDirective {
  private static readonly regex: RegExp = new RegExp(/^[0-9.,]*$/);
  private static readonly specialKeys: Array<string> = ['Backspace', 'Tab', 'Delete', 'End', 'Home', 'ArrowLeft', 'ArrowRight'];

  @Input('appOnlyPositive') onlyPositives = false;

  constructor(private el: ElementRef) {}

  @HostListener('input', ['$event'])
  onInputChange(event: UIEvent) {
    if (!this.onlyPositives) {
      return;
    }

    const inputValue = this.el.nativeElement.value.toString();
    if (!OnlyPositiveDirective.regex.test(inputValue)) {
      this.el.nativeElement.value = inputValue.replace(/[^0-9.,]/g, '');
      event.stopPropagation();
      return;
    }

    const parts = inputValue.split(/[,.]/);
    if (parts.length > 2) {
      this.el.nativeElement.value = parts.slice(0, 2).join('.');
    }

    const asNumber = parseFloat(inputValue.replace('.', ','));
    if (asNumber < 0 || isNaN(asNumber)) {
      event.stopPropagation();
      return;
    }
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (!this.onlyPositives) {
      return;
    }

    if (OnlyPositiveDirective.specialKeys.indexOf(event.key) !== -1) {
      return;
    }

    if (event.key === '-') {
      event.preventDefault();
      return;
    }

    const current: string = this.el.nativeElement.value;
    const next: string = current.concat(event.key);

    if (next && !OnlyPositiveDirective.regex.test(next)) {
      event.preventDefault();
      return;
    }
  }
}
