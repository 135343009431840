import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TabModel } from '@farm-portal/shared/modules/layout/tabular-page/models/tab-model';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'lib-tabular-page',
  templateUrl: './tabular-page.component.html',
  styleUrls: ['tabular-page.component.scss'],
  host: {
    '(window:resize)': 'onResize()'
  }
})
export class TabularPageComponent implements AfterViewInit, OnDestroy, OnChanges {
  @ViewChild('tabsContainer', { static: true }) tabsContainer!: ElementRef;
  @ViewChildren('tabItem') tabItems: ElementRef[] = [];

  @Input() routingBasePath: string;

  @Input() set pages(pages: TabModel[]) {
    this._pages = pages;
    this.visibleTabs = this.pages;
    if (this.tabsContainer) {
      this.updateVisibleAndOverflowingTabs();
    }
  }

  get pages() {
    return this._pages;
  }

  public visibleTabs: TabModel[] = [];
  public overflowTabs: TabModel[] = [];

  public activeId: string;
  public realActiveId: string;
  public isOverflowingTabActive = false;

  private routerEventsSubscription?: Subscription;
  private resizeTimeout?: number;
  private updateTabsTimeout?: number;
  private _pages: TabModel[] = [];

  constructor(
    private readonly router: Router,
    private readonly cdRef: ChangeDetectorRef
  ) {}

  public ngAfterViewInit(): void {
    this.updateActiveTab();
    this.routerEventsSubscription = this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
      this.updateActiveTab();
    });
    this.updateTabsTimeout = window.setTimeout(() => this.updateVisibleAndOverflowingTabs(), 200);
  }

  public ngOnDestroy(): void {
    this.routerEventsSubscription?.unsubscribe();

    if (this.resizeTimeout) {
      clearTimeout(this.resizeTimeout);
    }

    if (this.updateTabsTimeout) {
      clearTimeout(this.updateTabsTimeout);
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['routingBasePath']) {
      this.updateActiveTab();
    }

    if (changes['pages']) {
      this.updateActiveTab();
    }
  }

  public navigate(data: NgbNavChangeEvent): void {
    if (data.nextId === null) {
      return data.preventDefault();
    }
    this.router.navigateByUrl(`${this.routingBasePath}/${data.nextId}`);
  }

  public navigateByDropdown(page: TabModel) {
    this.router.navigateByUrl(`${this.routingBasePath}/${page.routingPath}`);
    this.updateActiveTab();
  }

  public isFirstTabSelected(): boolean {
    return this.pages && this.pages.length > 0 && this.pages[0].routingPath === this.getTabIdFromRouting();
  }

  public onResize(): void {
    if (this.resizeTimeout) {
      clearTimeout(this.resizeTimeout);
      this.resizeTimeout = undefined;
    }
    this.resizeTimeout = window.setTimeout(() => {
      this.updateVisibleAndOverflowingTabs();
    }, 100);
  }

  private updateActiveTab(): void {
    const activeTabRoute = this.getTabIdFromRouting();
    this.activeId = this.clearTabIdActions(activeTabRoute);
    this.realActiveId = this.activeId;

    if (this.isActiveTabInDropdown()) {
      this.activeId = '';
    }

    this.cdRef.detectChanges();
  }

  private updateVisibleAndOverflowingTabs(): void {
    const containerWidth = this.tabsContainer.nativeElement.offsetWidth;
    const dropdownWidth = 150;
    let tabsWidth = 0;

    this.visibleTabs = [];
    this.overflowTabs = [];

    for (const page of this.pages) {
      const tabWidth = this.getTabWidth(page);
      tabsWidth += tabWidth;
      if (tabsWidth > containerWidth - dropdownWidth) {
        this.overflowTabs.push(page);
      } else {
        this.visibleTabs.push(page);
      }
    }

    this.updateActiveTab();
    this.cdRef.detectChanges();
  }

  private getTabWidth(page: TabModel): number {
    if (!this.tabItems || this.tabItems.length === 0) return 200;
    const tabElement = this.tabItems.find(item => item.nativeElement.id === page.routingPath);
    return tabElement ? tabElement.nativeElement.offsetWidth : 200;
  }

  private isActiveTabInDropdown(): boolean {
    this.isOverflowingTabActive = this.overflowTabs.some(tab => tab.routingPath === this.realActiveId);
    this.cdRef.markForCheck();
    return this.isOverflowingTabActive;
  }

  private getTabIdFromRouting(): string {
    return this.router.url.replace(`/${this.routingBasePath}/`, '');
  }

  private clearTabIdActions(tabId: string) {
    return tabId.replace(/\/(edit|add)/, '');
  }
}
