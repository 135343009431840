import { Injectable } from '@angular/core';
import { ApplicationInsights, eSeverityLevel } from '@microsoft/applicationinsights-web';
import { AppConfigurationService } from '../configuration';

@Injectable({
  providedIn: 'root'
})
export class LoggerService {
  private isInitialized = false;
  private isClairityInitialized = false;
  get isClarityInitialized(): boolean {
    return this.isClairityInitialized;
  }
  private appInsights: ApplicationInsights;

  constructor(private appConfigurationService: AppConfigurationService) {}

  public trackException(errorObject: Error) {
    if (!this.isInitialized) {
      return;
    }

    if (!this.appInsights) {
      return;
    }

    this.appInsights.trackException({ exception: errorObject, severityLevel: eSeverityLevel.Error });
    this.appInsights.flush();
  }

  public initialize() {
    if (this.isInitialized) {
      return;
    }

    this.initializeClarity();
    this.initializeAppInsights();

    this.isInitialized = true;
  }

  private initializeAppInsights() {
    if (!this.appConfigurationService.configuration.appinsight_key) {
      console.error('Application insights initialization skipped due to missing key in config.');
      return;
    }
    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: this.appConfigurationService.configuration.appinsight_key
      }
    });
    this.appInsights.loadAppInsights();
  }

  private initializeClarity() {
    if (!this.appConfigurationService.configuration.clarity_tag) {
      console.error('MS Clarity initialization skipped due to missing key in config.');
      return;
    }
    const scriptTag = document.createElement('script');
    scriptTag.type = 'text/javascript';
    scriptTag.innerHTML = this.clarityScript(this.appConfigurationService.configuration.clarity_tag);
    document.head.appendChild(scriptTag);

    this.isClairityInitialized = true;
  }

  private clarityScript(projectId: string): string {
    return `(function(c,l,a,r,i,t,y){
    c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
    t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
    y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
  })(window, document, "clarity", "script", "${projectId}");`;
  }
}
