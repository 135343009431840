import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { ErrorHandler, Injectable, Injector, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { LoggerService } from '../logger';
import { NotificationService } from '../notification';

@Injectable()
export class ErrorsHandler implements ErrorHandler {
  private resourcesToIgnore = ['dc.services.visualstudio.com', 'login.microsoftonline.com'];

  constructor(
    private readonly injector: Injector,
    private readonly router: Router
  ) {}

  handleError(error: Error | HttpErrorResponse) {
    const ngZone = this.injector.get(NgZone);

    if (error instanceof HttpErrorResponse) {
      if (this.isIgnoredResource(error.url)) {
        return;
      }

      if (error.status == HttpStatusCode.NotFound) {
        return ngZone.run(() => {
          return this.router.navigateByUrl('notFound', { skipLocationChange: true });
        });
      }
    }

    // Ignore error from confirmation dialog
    if (
      error.message &&
      (error.message.indexOf('Uncaught (in promise): [object Number]') === 0 ||
        error.message.indexOf('Uncaught (in promise): [object Boolean]') === 0)
    ) {
      return;
    }

    // Ignore error from clarity
    if (error.message && error.message.includes('Clarity')) {
      console.error(error);
      return;
    }

    // Log the error
    try {
      const notificationService = this.injector.get(NotificationService);
      ngZone.run(() => notificationService.error(error));
      console.error(error);
      const appInsightsService = this.injector.get(LoggerService);
      appInsightsService.trackException(error);
    } catch (error) {
      console.error(error);
    }
  }

  private isIgnoredResource(url: string) {
    if (!this.resourcesToIgnore) {
      return false;
    }

    for (const resourceToIgnore of this.resourcesToIgnore) {
      if (url.indexOf(resourceToIgnore) > -1) {
        return true;
      }
    }

    return false;
  }
}
