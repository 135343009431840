import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { Subscription } from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';

@Component({
  selector: 'lib-filters-no-buttons-container',
  templateUrl: './filters-no-buttons-container.component.html'
})
export class FiltersNoButtonsContainerComponent<T> implements OnDestroy {
  @Output() filtersChanged = new EventEmitter<T>();

  @Input() set formGroup(form: UntypedFormGroup) {
    this._formGroup = form;
    this.initializeFormValueChangesSub(this._formGroup);
  }
  get formGroup() {
    return this._formGroup;
  }

  private readonly DEBOUNCE_TIME = 1500;
  private _formGroup: UntypedFormGroup;
  private filterValueChangesSubscription: Subscription;

  private initializeFormValueChangesSub(formGroup: UntypedFormGroup) {
    this.filterValueChangesSubscription = formGroup.valueChanges
      .pipe(
        debounceTime(this.DEBOUNCE_TIME),
        tap((formValue: T) => {
          if (!this.formGroup.valid) {
            this.formGroup.markAllAsTouched();
            return;
          }

          this.filtersChanged.emit(formValue);
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    if (this.filterValueChangesSubscription) {
      this.filterValueChangesSubscription.unsubscribe();
    }
  }
}
