import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'lib-filters-container',
  templateUrl: './filters-container.component.html',
  styleUrls: ['./filters-container.component.scss']
})
export class FiltersContainerComponent<T> {
  @Input() formGroup: UntypedFormGroup;

  @Output() public search = new EventEmitter<T>();

  public onSearch() {
    if (!this.formGroup.valid) {
      this.formGroup.markAllAsTouched();
      return;
    }

    this.search.emit(this.formGroup.value as T);
  }

  public onClean() {
    this.formGroup.reset();
    this.search.emit(this.formGroup.value as T);
  }
}
