import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appOnlyDecimal]'
})
export class OnlyDecimalDirective {
  private static readonly regex: RegExp = new RegExp(/^\d+$/);
  private static readonly specialKeys: Array<string> = ['Backspace', 'Delete', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight'];

  @Input('appOnlyDecimal') allowDecimals = true;

  constructor(private el: ElementRef) {}

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (this.allowDecimals) {
      return;
    }

    if (OnlyDecimalDirective.specialKeys.indexOf(event.key) !== -1) {
      return;
    }

    const current: string = this.el.nativeElement.value;
    const next: string = current.concat(event.key);

    if (next && !String(next).match(OnlyDecimalDirective.regex)) {
      event.preventDefault();
    }
  }
}
