import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { SettingsCoreProxyService } from '@farm-portal/core/settings/services/settings-core-proxy.service';
import { InputSelectModel } from '@farm-portal/shared/modules/form-inputs/models/input-select.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SpinnerService } from 'farmcloud-core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { finalize, map, take } from 'rxjs/operators';

@Component({
  selector: 'app-ngbd-modal-share',
  templateUrl: './ngbd-modal-share.component.html',
  styleUrls: ['./ngbd-modal-share.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class NgbdModalShareComponent {
  @Input() titleTranslateKey: string;
  @Input() messageTranslateKey: string;
  @Input() set currentShareIds(data: string[]) {
    this._currentShareIds$.next(data);
  }

  get currentShareIds$(): Observable<string[]> {
    return this._currentShareIds$.asObservable();
  }

  @Input() messageParams: string[];

  public get canConfirm(): boolean {
    return this.getFromControl('partners').valid;
  }

  public partnerOptions$: Observable<InputSelectModel<string>[]>;
  public partnerOptions: InputSelectModel<string>[];
  public partnerForm: UntypedFormGroup;

  private _currentShareIds$ = new BehaviorSubject<string[]>([]);

  constructor(
    public modal: NgbActiveModal,
    private settingsService: SettingsCoreProxyService,
    private spinner: SpinnerService,
    private fb: UntypedFormBuilder,
    private cdRef: ChangeDetectorRef
  ) {
    this.partnerForm = this.fb.group({
      partners: [null, Validators.required]
    });

    this.spinner.display();
    this.partnerOptions$ = combineLatest([
      this.settingsService.getPartnerList().pipe(
        take(1),
        finalize(() => this.spinner.hide())
      ),
      this.currentShareIds$
    ]).pipe(
      map(([options, currentShareIds]) => {
        const partnerOptions = options.items.map(option => ({ value: option.id, label: option.name }));
        this.partnerOptions = partnerOptions;
        this.getFromControl('partners').patchValue(currentShareIds);
        this.cdRef.detectChanges();
        return partnerOptions;
      })
    );
  }

  public getFromControl(name: string): UntypedFormControl {
    return this.partnerForm.get(name) as UntypedFormControl;
  }

  public onClose() {
    const selectedPartnersIds = this.getFromControl('partners').value;
    const selectedPartners = this.partnerOptions
      .filter(partner => selectedPartnersIds.includes(partner.value))
      .map(m => ({
        orgId: m.value,
        orgName: m.label
      }));
    this.modal.close(selectedPartners);
  }
}
