import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

export function getFormControl(formGroup: UntypedFormGroup, path: string): UntypedFormControl {
  if (!formGroup) {
    throw new Error(`Form group is null or undefined`);
  }

  const control = formGroup.get(path);
  if (!control) {
    throw new Error(`Unable to find control ${path} in ${formGroup}`);
  }

  if (!(control instanceof UntypedFormControl)) {
    throw new Error(`Expected control to be instance of FormControl but received ${typeof control}`);
  }

  return control as UntypedFormControl;
}
