import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TimeoutError } from '@as/api';
import { TranslateService } from '../translation/translate.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorMessageService {
  constructor(private translateService: TranslateService) {}

  getTranslatedErrorMessage(error: Error | HttpErrorResponse, defaultMessage?: string) {
    // No Internet connection
    if (!navigator.onLine) {
      return this.translateService.translate('APPLICATION.ERRORS.noConnection');
    }

    if (error instanceof TimeoutError) {
      return this.translateService.translate(`API.ERRORS.${error.message}`);
    }

    if (this.isClientError(error)) {
      return this.handleClientError(error);
    }

    if (defaultMessage) {
      return this.translateService.translate(defaultMessage);
    }

    return 'Unhandled error';
  }

  private handleClientError(error: HttpErrorResponse): string {
    if (Array.isArray(error.error)) {
      return error.error
        .map(err => {
          return this.handleAsError(err);
        })
        .join('\n');
    }

    return this.handleAsError(error.error);
  }

  private handleAsError(error: { code: string; message: string; Message: string }): string {
    if (error && error.code) {
      const errorTranslationKey = `API.ERRORS.${error.code}`;
      const translatedValue = this.translateService.translate(errorTranslationKey);
      if (errorTranslationKey != translatedValue) {
        return translatedValue;
      }
    }

    return error.message ?? error.Message;
  }

  private isClientError(error: Error): error is HttpErrorResponse {
    return error instanceof HttpErrorResponse && error.status >= 400 && error.status < 500;
  }
}
