import { Injectable } from '@angular/core';
import { FileModel, ImageModel } from '@as/api';

@Injectable({
  providedIn: 'root'
})
export class FileUtilService {
  public downloadFile = (link: string, target: '_blank' | '_self' = '_blank') => {
    window.open(link, target);
  };

  public downloadFileWithNameAsync = async (url: string, fileName: string) => {
    const response = await fetch(url, { method: 'GET' });
    const blob = await response.blob();
    const urlDownload = window.URL.createObjectURL(blob);
    const link = document.createElement('a');

    link.href = urlDownload;
    link.setAttribute('download', fileName);
    link.click();
  };

  public downloadImageModelsAsync = async (data: FileModel[] | ImageModel[]) => {
    for (const image of data) {
      if (!image.link) {
        continue;
      }
      const response = await fetch(image.link, { method: 'GET' });
      const blob = await response.blob();
      const urlDownload = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = urlDownload;
      if (this.isFileModel(image) && image.rootFileName) {
        link.setAttribute('download', image.rootFileName);
      }
      link.click();
    }
  };
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  private isFileModel(object: any): object is FileModel {
    return 'rootFileName' in object;
  }
}
