export interface IContactDto {
  id: string;
  name: string;
  street: string;
  buildingNumber: string;
  roomNumber: string;
  city: string;
  postCode: string;
  commune: string;
  state: string;
  county: string;
  country: string;
  herdRegistrationNumber: string;
  farmRegistrationNumber: string;
  taxNumber: string;
  vatNumber: string;
  regon: string;
  hasFarmPortalAccount: string;
  locationsJSON: string;
  logoSmallImage: string;
  logoMediumImage: string;
  contactMainEmail: string;
  homepage: string;
}
